import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import Button from '@/components/atoms/button';
import Logo from '@/components/atoms/logo';
import Text from '@/components/atoms/text';
import Link from '@/components/atoms/link';
import Separator from '@/components/atoms/separator';

const websiteDomain = 'https://eis.adido.dev';

const WebsiteFooter = ({ auth, getQuoteModalOpen, client, policyNavItems, ...props }) => (
	<footer className="bg-eis-black">
		<div className="flex flex-col w-full gap-12 px-6 py-12 mx-auto md:px-10 lg:px-14 xl:px-eis-72 max-w-eis-1440">
			<div className="flex flex-col justify-between gap-12 sm:gap-0 sm:flex-row">
				<Link isGeneralLink className="block" href={websiteDomain}>
					<Logo name="logo-big-white" />
					<span className="sr-only">events insurance logo</span>
				</Link>
				<Button
					variant="primary-white"
					type="button"
					className="self-start"
					onClick={() => {
						getQuoteModalOpen();
					}}
				>
					Get a quote
				</Button>
			</div>

			<div className="flex flex-col gap-10 md:grid md:grid-cols-2 xl:grid-cols-5">
				<div className="relative flex flex-col md:pb-8 gap-4 after:absolute after:-bottom-[19.5px] after:left-0 after:right-0 after:h-px after:w-full md:after:top-0 md:after:left-auto md:after:-right-[19.5px] md:after:bottom-0 md:after:w-px md:after:h-full after:bg-eis-grey-68">
					<Text type="p" variant="bold" className="text-white">
						Trading details
					</Text>
					<Text type="small" variant="normal" className="text-white">
						Event Insurance Services is a limited company registered in England and Wales. Registered number: 3238686 Event Insurance Services Limited are
						authorised and regulated by the Financial Conduct Authority, Ref No. 309998
					</Text>
				</div>

				<div className="relative flex flex-col md:pb-8 xl:col-span-2 gap-4 after:absolute after:-bottom-[19.5px] after:left-0 after:right-0 after:h-px after:w-full md:after:left-auto md:after:w-0 md:after:h-0 xl:after:top-0 xl:after:-right-[19.5px] xl:after:bottom-0 xl:after:w-px xl:after:h-full after:bg-eis-grey-68">
					<Text type="p" variant="bold" className="text-white">
						Policies
					</Text>
					<ul role="menubar" aria-label="header menu" className="grid sm:grid-cols-2 gap-x-8 gap-y-3">
						{policyNavItems.map((navItem, idx) => {
							const linker = client?.id ? `/quote/${navItem.uri}?client_id=${client.id}` : `/quote/${navItem.uri}`;

							return (
								<li className="blcok text-14xs leading-eis-1.29" key={`nav-item-${idx}`}>
									<Link isGeneralLink={!auth?.user} className="block" href={auth?.user ? linker : navItem.externalUri}>
										<Text type="small" variant="normal" className="block text-white">
											{navItem.name}
										</Text>
									</Link>
								</li>
							);
						})}
					</ul>
				</div>

				<div className="relative flex flex-col md:pb-8 gap-4 after:absolute after:-bottom-[19.5px] after:left-0 after:right-0 after:h-px after:w-full md:after:top-0 md:after:left-auto md:after:-right-[19.5px] md:after:bottom-0 md:after:w-px md:after:h-full after:bg-eis-grey-68">
					<Text type="p" variant="bold" className="text-white">
						Company
					</Text>
					<ul className="flex flex-col gap-3">
						<li className="blcok text-14xs leading-eis-1.29">
							<Link isExternal isGeneralLink className="block" href={`${websiteDomain}/about-us/`}>
								<Text type="small" variant="normal" className="block text-white">
									About us
								</Text>
							</Link>
						</li>
						<li className="blcok text-14xs leading-eis-1.29">
							<Link isExternal isGeneralLink className="block" href={`${websiteDomain}/document-centre/`}>
								<Text type="small" variant="normal" className="block text-white">
									Documents
								</Text>
							</Link>
						</li>
						<li className="blcok text-14xs leading-eis-1.29">
							<Link isExternal isGeneralLink className="block" href={`${websiteDomain}/`}>
								<Text type="small" variant="normal" className="block text-white">
									Claims
								</Text>
							</Link>
						</li>
						<li className="blcok text-14xs leading-eis-1.29">
							<Link isExternal isGeneralLink className="block" href={`${websiteDomain}/`}>
								<Text type="small" variant="normal" className="block text-white">
									Privacy and cookies
								</Text>
							</Link>
						</li>
					</ul>
				</div>

				<div className="flex flex-col gap-4 pb-8">
					<Text type="p" variant="bold" className="text-white">
						Contact
					</Text>
					<div className="flex flex-col gap-3">
						<div className="flex flex-col gap-4">
							<Text type="small" variant="normal" className="block text-white">
								Event House, <br />
								20A Headlands Business Park, <br />
								Ringwood, Hampshire, <br />
								BH24 3PB, United Kingdom
							</Text>
							<Text type="small" variant="normal" className="block text-white">
								Hours of business: <br />
								Monday - Friday 9:00 - 17:00
							</Text>
						</div>

						<div className="flex flex-col gap-4">
							<Text type="small" variant="normal" className="block text-white">
								Tel: 01425 470360
							</Text>
							<Text type="small" variant="normal" className="block text-white">
								FAX: 01425 474905
							</Text>
							<Text type="small" variant="normal" className="block text-white">
								Email:{' '}
								<Link
									isGeneralLink
									href="mailto:info@events-insurance.co.uk"
									variant="dark"
									size="small"
									className="underline underline-offset-2 hover:after:w-0"
								>
									info@events-insurance.co.uk
								</Link>
							</Text>
							<Link href="#" variant="dark" size="small" className="block underline underline-offset-2 hover:after:w-0">
								Contact form
							</Link>
						</div>
					</div>
				</div>
			</div>

			<Separator orientation="horizontal" className="bg-eis-grey-68" />

			<div className="flex flex-col justify-between gap-12 md:gap-0 md:flex-row">
				<div className="flex flex-col">
					<Text type="small" variant="normal" className="block text-white">
						© Copyright 2023 - Event Insurance Services. All rights reserved.
					</Text>
					<Link isExternal isGeneralLink className="block text-white" size="small" href="/">
						Privacy and cookies
					</Link>
				</div>

				<ul className="flex flex-row gap-3" aria-label="stayconnected">
					<li>
						<Link
							isExternal
							isGeneralLink
							href="https://twitter.com/EventsInsurance"
							title="X"
							rel="noopener noreferrer"
							className="block transition-all rounded-full focus-visible:outline focus-visible:outline-4 focus-visible:outline-notificationsWarning hover:shadow-blue-drop-shadow"
						>
							<img className="block w-10 h-10 shrink-0" src={`${websiteDomain}/site/assets/icons/x.svg`} alt="X icon" />
							<span className="sr-only">X</span>
						</Link>
					</li>
					<li>
						<Link
							isExternal
							isGeneralLink
							href=""
							title="LinkedIn"
							rel="noopener noreferrer"
							className="block transition-all rounded-full focus-visible:outline focus-visible:outline-4 focus-visible:outline-notificationsWarning hover:shadow-blue-drop-shadow"
						>
							<img className="block w-10 h-10 shrink-0 ls-is-cached lazyloaded" src={`${websiteDomain}/site/assets/icons/linkedin.svg`} alt="LinkedIn icon" />
							<span className="sr-only">LinkedIn</span>
						</Link>
					</li>
					<li>
						<Link
							isExternal
							isGeneralLink
							href="https://www.facebook.com/eventinsuranceservices"
							title="Facebook"
							rel="noopener noreferrer"
							className="block transition-all rounded-full focus-visible:outline focus-visible:outline-4 focus-visible:outline-notificationsWarning hover:shadow-blue-drop-shadow"
						>
							<img className="block w-10 h-10 shrink-0 ls-is-cached lazyloaded" src={`${websiteDomain}/site/assets/icons/facebook.svg`} alt="Facebook icon" />
							<span className="sr-only">Facebook</span>
						</Link>
					</li>
					<li>
						<Link
							isExternal
							isGeneralLink
							href="https://www.instagram.com/eventinsuranceservicesltd_/"
							title="Instagram"
							rel="noopener noreferrer"
							className="block transition-all rounded-full focus-visible:outline focus-visible:outline-4 focus-visible:outline-notificationsWarning hover:shadow-blue-drop-shadow"
						>
							<img className="block w-10 h-10 shrink-0 ls-is-cached lazyloaded" src={`${websiteDomain}/site/assets/icons/instagram.svg`} alt="Instagram icon" />
							<span className="sr-only">Instagram</span>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	</footer>
);

export default WebsiteFooter;
